import Uheader from "./uheader";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Ufooter from "./ufooter";

function Resetupass(){

    let uid=sessionStorage.getItem('uid')
    let upass=sessionStorage.getItem('upass')

    const history=useNavigate()
    const [cups, setCups] = useState("")
    const [nps, setNps] = useState("")
    const [cps, setCps] = useState("")

   

    const updatelogin = async(e) => {
      e.preventDefault()
      const fdata = new FormData()
      fdata.append('cups', cups)
      fdata.append('nps', nps)
      fdata.append('cps', cps)
      fdata.append('uid', uid)
      fdata.append('upass', upass)

      if(nps==="")
      {
          alert("Please enter Password :");
          document.getElementById("nps").focus();
          return false;
      }
      if(nps.length<8)
      {
          alert("Password should be minimum 8 charater :");
          document.getElementById("nps").focus();
          return false;
      }
  if(nps.length>16)
      {
          alert("Password should be maxmum 16 charater :");
          document.getElementById("nps").focus();
          return false;
      }

  if(nps.match(/^[a-zA-Z0-9]+$/))
      {
          alert("Password should be alphanumeric with special character :");
          document.getElementById("nps").focus();
          return false;
      }
 
      if(cps==="")
      {
          alert("Please enter Password :");
          document.getElementById("cps").focus();
          return false;
      }
      if(cps.length<8)
      {
          alert("Password should be minimum 8 charater :");
          document.getElementById("cps").focus();
          return false;
      }
  if(cps.length>16)
      {
          alert("Password should be maxmum 16 charater :");
          document.getElementById("cps").focus();
          return false;
      }
      if(cps.match(/^[a-zA-Z0-9]+$/))
      {
          alert("Password should be alphanumeric with special character :");
          document.getElementById("cps").focus();
          return false;
      }
      
  
      await axios.post("http://localhost/matrimony/resetupass.php", fdata).then((res) => {
        if (res.data === 1) {
          
          alert('Password Changed successfully ')
  
        }
        else if(res.data==3)
        {
            alert('old Password Not match ')  
        }
        
        else if(res.data==2)
        {
            alert('Confirm Password Not match ')  
        }
        else {
          alert('Error in code')
        }
      })
    }

  
return<>
<Uheader/>
<center>
    <form onSubmit={e => updatelogin(e)} >

<div class="card text-center resetpass" >
    <div class="card-header h5 text-white bg-primary">Password Reset</div>
    <div class="card-body px-5">
        <p class="card-text py-2">
            Enter your current Password & reset your password.
        </p>
        <div class="form-outline">
            <input type="text"  onChange={e=>setCups(e.target.value)} id="cups" class="form-control my-3" placeholder="Enter Current Password" />
            <input type="text" onChange={e=>setNps(e.target.value)} id="nps" class="form-control my-3" placeholder="Enter New Password" />
            <input type="password"  onChange={e=>setCps(e.target.value)} id="cps" class="form-control my-3" placeholder="Confirm New Password"/>


        </div>
        <input type='submit' value='Login' class="btn btn-primary w-100"/>
        <div class="d-flex justify-content-between mt-4">
        </div>
    </div>
</div>
</form>
</center>
<Ufooter/>

</>
}
export default Resetupass;