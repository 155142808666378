import Header from "./header";
import { useState} from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";

function Login() {
  const history=useNavigate()
  const [uname, setUname] = useState("")
  const [pass, setPass] = useState("")

  const checklogin = async(e) => {
    e.preventDefault()
    const fdata = new FormData()
    fdata.append('uname', uname)
    fdata.append('pass', pass)

    await axios.post("http://localhost/matrimony/login.php", fdata).then((res) => {
      if (res.data === 1) {
        sessionStorage.setItem("un",uname);
        sessionStorage.setItem("ps",pass);

        alert('Logged successfully')
        history('/admin_home') 

      }
      else {
        alert('Error in code')
      }
    })
  }

  function noBack()
  {
    window.history.forward();
  }
  return <>
  <body onLoad={noBack} onpageshow='if(event.persisted) noBack()' onunload='' >
    <Header />

    <center>
      <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="card my-5">

       


        <form onSubmit={e => checklogin(e)} class="card-body cardbody-color p-lg-5">

        <div class="text-center">
              <img src="https://cdn.pixabay.com/photo/2016/03/31/19/56/avatar-1295397__340.png" class="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                width="200px" alt="profile"/>
            </div>

            <div class="mb-3">
              <input type="text" class="form-control" onChange={e => setUname(e.target.value)} id="Username" aria-describedby="emailHelp"
                placeholder="User Name"/>
            </div>
            <div class="mb-3">
              <input type="password"  class="form-control" onChange={e => setPass(e.target.value)} id="password" placeholder="Password"/>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-color px-5 mb-5 w-100"> Login </button>
              </div>
            </form>
        </div>


      </div>
    </div>
  </div>

    </center>
    </body>
  </>
}
export default Login;
