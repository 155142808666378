function Admin_autho()
{
    let un=sessionStorage.getItem("un")
    let ps=sessionStorage.getItem("ps")

    if(un!="" && ps!="")
    {

    }
    else{
        window.location='/admin_logout';
    }

    return<>
    </>
}
export default Admin_autho;