import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Uheader from "./uheader";

function Uprofile(){

    let uid=sessionStorage.getItem('uid')
    const [reg, setReg] = useState([])
    useEffect(() => {
        loadreg();
    })
    
    const loadreg = async () => {
        const result = await axios.get("http://localhost/matrimony/uprofile.php?uid="+uid);
        setReg(result.data.records);
    }
    const imgurl="http://localhost/matrimony/upload/"
    const imgurl1="http://localhost/matrimony/upload/dp.png"

    return<>
    <Uheader/>


<center>
            <form>
                <div class='container mt-5'>
                    <div class='row'>
                        <div class='col-3'></div>
                        <div class='col-12'>


                            <div class="card border">
                                    <div class="card-header border-bottom">
                                        <h4 class="card-header-title">Personal Information</h4>
                                    </div>

                                    <div class="card-body">
                                    {reg.map((reg, index) => (

                                        <form class="row g-3">
                                            <div class="col-12">
                                            <center>
                                                {(()=>{
                                                    if(reg.img==="")
                                                    {
                                                        return<> <img src={imgurl1} className="profileimg me-3"/></>


                                                    }
                                                    else{
                                                        return<> <img src={imgurl+reg.img} className="profileimg me-3"/> </>

                                                    }
                                                })()}
                                                <Link  to={'/profileimgedit/'}> <img src='img/imgicon.png' width={50} height={50}></img></Link>
                                            </center>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Full Name</label>                                                
                                                <label class="text-dark">{reg.na}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">E-mail id</label>                                                
                                                <label class="text-dark">{reg.em}</label>                                                
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Gender</label>                                                
                                                <label class="text-dark">{reg.gen}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Create profile for</label>                                                
                                                <label class="text-dark">{reg.prof}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Date of birth</label>                                                
                                                <label class="text-dark">{reg.dob}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Education</label>                                                
                                                <label class="text-dark">{reg.edu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Occupation  </label>                                                
                                                <label class="text-dark">{reg.occu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Height</label>                                                
                                                <label class="text-dark">{reg.height}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Weight</label>                                                
                                                <label class="text-dark">{reg.weight}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Color</label>                                                
                                                <label class="text-dark">{reg.color}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Income</label>                                                
                                                <label class="text-dark">{reg.income}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Marital status</label>                                                
                                                <label class="text-dark">{reg.ms}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Religion</label>                                                
                                                <label class="text-dark">{reg.religion}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Jati</label>                                                
                                                <label class="text-dark">{reg.jati}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Gotra</label>                                                
                                                <label class="text-dark">{reg.gotra}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Manglik</label>                                                
                                                <label class="text-dark">{reg.manglik}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mother Tongue</label>                                                
                                                <label class="text-dark">{reg.mt}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Category</label>                                                
                                                <label class="text-dark">{reg.cat}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Fathers Name</label>                                                
                                                <label class="text-dark">{reg.fatherna}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Fathers Occupation</label>                                                
                                                <label class="text-dark">{reg.fatheroccu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mothers Name</label>                                                
                                                <label class="text-dark">{reg.motherna}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mothers Occupation</label>                                                
                                                <label class="text-dark">{reg.motheroccu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Brother </label>                                                
                                                <label class="text-dark">{reg.bro}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3"> Sister</label>                                                
                                                <label class="text-dark">{reg.sis}</label>                                                
                                            </div>

                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mobile Number</label>                                                
                                                <label class="text-dark">{reg.cno}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Address</label>                                                
                                                <label class="text-dark">{reg.addr}</label>                                                
                                            </div>
                                            
                                            <div class="col-12 text-end">
                                                <Link to={'/edituprofile/'+reg.id} class="btn btn-primary mb-0"><i class="bi bi-pencil-square"></i> Edit Profile</Link>
                                            </div>
                                            
                                        </form>
                                    ))}

                                    </div>
                                </div>   

                        </div>
                    </div>
                </div>
            </form>
        </center>
    
    </>
}
export default Uprofile;