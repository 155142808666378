import Ufooter from "./ufooter";
import Uheader from "./uheader";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function Dash(){


    let uid=sessionStorage.getItem("uid")
    const [mydata, setRegister] = useState([])
    useEffect(() => {
        loadreg();
    },[])
    
    const loadreg = async () => {
        const result = await axios.get("http://localhost/matrimony/mymatches.php?uid="+uid);
        setRegister(result.data.records);
    }

    const imgurl="http://localhost/matrimony/upload/"
    const imgurl1="http://localhost/matrimony/upload/dp.png"



    return<>

    <Uheader/>
    <br/><br/><br/><br/>



    <div class="container py-5">
    <div class="row text-center text-white">
        
        <div class="col-lg-8 mx-auto">
        <div class="card-header card border">
                                        <h4 class="card-header-title txt"> Dashboard</h4>
                                    </div>
            <h1 class="display-4"></h1>
        </div>
        
    </div>
    <div class="row">
            {mydata.map((mydata, index) => (

        <div class="col-lg-8 mx-auto">

            <ul class="list-group shadow">
                
                <li class="list-group-item mb-3">
                    
                    <div class="media align-items-lg-center flex-column d-flex flex-lg-row p-3">
                    {(()=>{
                            if(mydata.img==="")
                                {
                            return<> <img src={imgurl1} className="profileimg me-3"/></>


                                }
                            else{
                             return<> <img src={imgurl+mydata.img} className="profileimg me-3"/> </>

                                }
                             })()}
                                                    
                        <div class="media-body order-2 order-lg-1">
                            <h5 class="mt-0 font-weight-bold mb-2">{mydata.na}</h5>
                            <p class="font-italic text-muted mb-0 small">{mydata.gen}</p>
                            <div class=" align-items-center justify-content-between mt-1">
                                <h6 class="font-weight-bold my-2">{mydata.dob} Year </h6> 
                                <div><h6 class="font-weight-bold my-2">{mydata.occu}</h6></div>
                               <div className="vbtn">
                               <Link to={'/view_details/'+mydata.em} class="btn btn-outline-primary"> View Details</Link>
 
                                </div>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div> 
                    
                </li>               
            </ul> 
        </div>
             ))}

    </div>

</div>


    <Ufooter/>
    
    </>
}
export default Dash;