import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';
import User_autho from './user_autho';

function Uheader() {
  return <>
  <User_autho/>
  <nav class="navbar navbar-expand-lg hd fixed-top"><Link class="nav-link active" aria-current="page" to="/"> <img src='/img/logo.png' width={280} height={80} /></Link>
      <div class="container-fluid">
        <Link class="navbar-brand" to="#">Navbar</Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/dash">Home</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/plan">Plan</Link>
            </li>
                
            <li class="nav-item dropdown">
              <Link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Account
              </Link>
              <ul class="dropdown-menu">
                <li><Link class="dropdown-item" to="/uprofile">Profile</Link></li>
                <li><Link class="dropdown-item" to="/resetupass"> Reset Password</Link></li>

                <li><Link class="dropdown-item" to="/ulogout"> Log out</Link></li>
              </ul>
            </li>
            
          </ul>

        </div>
      </div>
    </nav>
  </>
}
export default Uheader;