import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';
import Admin_autho from './admin_autho';

function Adminheader() {
  return <>
  <Admin_autho/>
    <nav class="navbar navbar-expand-lg hd"><Link class="nav-link active" aria-current="page" to="/"> <img src='/img/logo.png' width={280} height={80} /></Link>
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/admin_home">Home</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/user_detail">User Details</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/createplan"> Createplan </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/resetapass">Reset Password</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/admin_logout">Logout</Link>
            </li>   
          </ul>

        </div>
      </div>
    </nav>
  </>
}
export default Adminheader;