import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function View_user_plan(){

    const {uid}=useParams()

    const [mydata, setRegister] = useState([])
    useEffect(() => {
        loadreg();
    },[])
    
    const loadreg = async () => {
        const result = await axios.get("http://localhost/matrimony/view_user_plan.php?uid="+uid);
        setRegister(result.data);
    }
    const imgurl="http://localhost/matrimony/upload/"

    return<>
<center>
<section class="pt-4">
		<form>
		<div class="container">
			<div class="row">
				<div class="col-md-10 col-xl-8 mx-auto">


					<div class="card shadow">
						

                        <div>

						<div class="card-body text-center p-4">

                            {
								(()=>{

									if(mydata.found==1)
									{
										return<>
										<h1 class="card-title fs-3">🎊 Transaction Detail of {mydata.uname}🎊</h1>
							<p class="lead mb-3"></p>

							<h5 class="text-primary mb-4"></h5>
							

							<div class="row justify-content-between text-start mb-4">
								<div class="col-lg-12">
									<ul class="list-group list-group-borderless">
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-vr fa-fw me-2"></i>Transaction ID:</span>
											<span class="h6 fw-normal mb-0">{mydata.tid}</span>
										</li>
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-person fa-fw me-2"></i>Transaction by:</span>
											<span class="h6 fw-normal mb-0">{mydata.uname} </span>
										</li>
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-wallet2 fa-fw me-2"></i>Payment Date:</span>
											<span class="h6 fw-normal mb-0"> {mydata.tdate} </span>
										</li>
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>Plan Type:</span>
											<span class="h6 fw-normal mb-0">{mydata.pname}</span>
										</li>
										
										
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>Plan Price:</span>
											<span class="h6 fw-normal mb-0">${mydata.price}</span>
										</li>
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>GST (18%):</span>
											{
													(()=>{
														let gst=mydata.price*18/100;
											
												
														return<>												
														<span class="h6 fw-normal mb-0">${gst}</span>
														</>
													})()
												}
										</li>
										<li class="list-group-item d-sm-flex justify-content-between align-items-center">
											<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>Total Price:</span>
											{
													(()=>{
														let gst1=(mydata.price)*18/100;
											
														let tamt1=parseFloat(mydata.price)+parseFloat(gst1);
														return<>												
														<span class="h5 mb-0">${tamt1}</span>
														</>
													})()
												}										</li>
									</ul>
								</div>
							</div>
							<div class="d-sm-flex justify-content-sm-end d-grid">
							<Link to="/user_detail" class="btn btn-primary mb-0">Go Back </Link>


								<div class="dropdown me-sm-2 mb-2 mb-sm-0">
									<Link to="#" class="btn btn-light mb-0 w-100" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
										<i class="bi bi-share me-2"></i>Share
									 </Link>

									<ul class="dropdown-menu dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
										<li><Link  class="dropdown-item" to="#"><i class="bi bi-twitter me-2"></i>Twitter </Link></li>
										<li><Link class="dropdown-item" to="#"><i class="bi bi-facebook me-2"></i>Facebook </Link></li>
										<li><Link class="dropdown-item" to="#"><i class="bi bi-linkedin me-2"></i>LinkedIn </Link></li>
										<li><Link class="dropdown-item" to="#"><i class="bi bi-clipboard me-2"></i>Copy link </Link></li>
									</ul>
								</div>

								<Link to="#" class="btn btn-primary mb-0"><i class="bi bi-file-pdf me-2"></i>Download PDF </Link>
							</div>

										</>
									}
									else{
										return<>
										<h1 class="card-title fs-3">🎊 No Plan Found Here...🎊</h1>
										<Link to="/user_detail" class="btn btn-primary mb-0">Go Back </Link>

										</>
									}
								})()
							}
						
							


						</div>
						</div>
						


					</div>


				</div>
			</div>
		</div>
		</form>
	</section>
        </center>
    
    </>
}
export default View_user_plan;