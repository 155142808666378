import Header from "./header";
import Footer from "./footer";


function Home(){
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/649695f694cf5d49dc5f8de2/1h3m3jjuc';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

    return<>

    <Header/>

    <div id="carouselExampleAutoplaying" class="carousel slide tmm" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src="img/a1.jpg" class="d-block w-100" alt="img" height={600}/>
            <div className="box">We can turn your wedding dreams into a reality!</div>
            </div>
            <div class="carousel-item">
            <img src="img/a2.jpg" class="d-block w-100" alt="img" height={600}/>

            </div>
            <div class="carousel-item">
            <img src="img/a8.jpg" class="d-block w-100" alt="img" height={600}/>
            <div className="box2">Meet your soulmate here!</div>

            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    
    <section id="contact">
    <Footer/>
    
    </section>
    
    
    </>
}
export default Home;
