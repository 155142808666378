import Header from "./header";
import { useState, useEffect } from "react";
import axios from "axios";

function Searchdisplay(){

    const [reg, setReg] = useState([])
    let gen=sessionStorage.getItem('gen')
    let religion=sessionStorage.getItem('religion')
    let age=sessionStorage.getItem('age')
    let age1=sessionStorage.getItem('age1')
    useEffect(() => {
        loadreg();
    },[])
    const loadreg = async () => {
        const formdata = new FormData()
        formdata.append('gen',gen)
        formdata.append('religion',religion)
        formdata.append('age',age)
        formdata.append('age1',age1)
        
        
        const result = await axios.post("http://localhost/matrimony/search.php",formdata);
        setReg(result.data.record);
    }
    const imgurl="http://localhost/matrimony/upload/"


return<>
<Header/>

<div class="container py-5">
    <div class="row text-center text-white mb-5">
        <div class="col-lg-7 mx-auto">
            <h1 class="display-4"></h1>
        </div>
    </div>
    <div class="row">
        {reg.map ((reg, index)=>(
        <div class="col-lg-8 mx-auto">

            <ul class="list-group shadow">
                
                <li class="list-group-item mb-3">
                    
                    <div class="media align-items-lg-center flex-column d-flex flex-lg-row p-3">

                    
                        
                    <img src={imgurl+reg.img} alt="Generic placeholder image" width="200" class="ml-lg-5 me-3"/>
                        
                        <div class="media-body order-2 order-lg-1">
                        <h5 class="mt-0 font-weight-bold mb-2">{reg.na}</h5>

                            <p class="font-italic text-muted mb-0 small">{reg.gen}</p>

                            <p class="font-italic text-muted mb-0 small">{reg.religion}</p>
                            <h5 class="mt-0 font-weight-bold mb-2">{reg.occu}</h5>

                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                </li>               
            </ul> 
        </div>
        ))}
    </div>
</div>



</>
}
export default Searchdisplay;