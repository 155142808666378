import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Uheader from "./uheader";
function Edituprofile(){

    const {uid}=useParams()
    let history=useNavigate()
    useEffect(() => {
        loadreg1();
    },[])
    
    const loadreg1 = async () => {
        const result = await axios.get("http://localhost/matrimony/edituprofile.php?uid="+uid);
        setReg1(result.data);
    }

     const [reg1, setReg1 ]= useState({
       id:"",
       na:"",
       gen:"",
       prof:"",
       dob:"",
       edu:"",
       occu:"",
       height:"",
       weight:"",
       color:"",
       income:"",
       
       
       ms:"",
       religion:"",
       jati:"",
       gotra:"",

       manglik:"",
       mt:"",
       cat:"",
       fatherna:"",
       fatheroccu:"",
       motherna:"",
       motheroccu:"",
       bro:"",
       sis:"",
       
       cno:"",
       addr:"",
       
    })

    const {id, na, gen, prof, dob, edu, occu, height, weight, color, income, ms, religion, jati, gotra, manglik, mt, cat, fatherna, fatheroccu, motherna, motheroccu, bro, sis, cno, addr}=reg1;
    const handlechange=(e)=>{
        setReg1({...reg1,[e.target.name]:e.target.value});
    }
    
    const updateform=async(e)=>{
        e.preventDefault()
        let reg2=JSON.stringify(reg1);
        await axios.post("http://localhost/matrimony/updateuprofile.php",reg2).then((res)=>{
          if(res.data===1)
          {
              alert('Updated successfully')
              history('/uprofile')
          }
          else{
              alert('Updated successfully')
              history('/uprofile')
              
          }
        })     

  }

    return<>
    <Uheader/>


<center>
            <form onSubmit={e=>updateform(e)}>
    
        <table>
            
        <div class="card-header border-bottom ">
                <h4 class="card-header-title ">Edit Your Profile </h4>
        </div>
        <tr>
                <td><input type="hidden" class='form-control' name='id' value={id} id="name" onChange={e=>handlechange(e)}></input></td>
            </tr>
        
            <tr>
                <td>Name : </td>
                <td><input type="text" class='form-control' name='na' value={na} id="name" onChange={e=>handlechange(e)}></input></td>
            </tr>
           
                    
            <tr>
                <td>Gender : </td>
                <td>
                    

                <select  class='inp form-control' name='gen'  id="gen" onChange={e=>handlechange(e)}>
                    <option value={'gen'}>{gen}</option>
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option>
                    </select>
                    </td>
            </tr>
            <tr>
                <td>Create Profile For : </td>
                <td>
                    <select  class='inp form-control' name='prof' value={prof} id="prof" onChange={e=>handlechange(e)}>
                    <option value={''}>Please Select</option>
                    <option value={'Self'}>Self</option>
                    <option value={'Son'}>Son</option>
                    <option value={'Daughter'}>Daughter</option>
                    <option value={'Sister'}>Sister</option>
                    <option value={'Brother'}>Brother</option>
                    </select>
                    </td>
            </tr>
            
            <tr>
                <td>Date of birth : </td>
                <td>
                    <input type="text" class='inp form-control'  name='dob' value={dob}  onChange={e=>handlechange(e)}></input>
                        
                </td>
            </tr>
            <tr>
                <td>Education : </td>
                <td>
                    <input type="text" class='inp form-control'  name='edu' value={edu}  onChange={e=>handlechange(e)}></input>
                        
                </td>
            </tr>
            <tr>
                <td>Occupation : </td>
                <td>
                    <input type="text" class='inp form-control'  name='occu' value={occu}  onChange={e=>handlechange(e)}></input>
                        
                </td>
            </tr>
            <tr>
                <td>Height : </td>
                <td>
                <select  class='inp form-control' name='height' value={height} id="height" onChange={e=>handlechange(e)}>
                    <option value={''}> Please Select</option>
                    <option value={'4.5'}>4.5'</option>
                    <option value={'4.6'}>4.6'</option>
                    <option value={'4.7'}>4.7'</option>
                    <option value={'4.8'}>4.8'</option>
                    <option value={'4.9'}>4.9'</option>
                    <option value={'4.10'}>4.10'</option>
                    <option value={'4.11'}>4.11'</option>
                    <option value={'4.12'}>4.12'</option>
                    <option value={'5.0'}>5.0'</option>
                    <option value={'5.5'}>5.5'</option>
                    <option value={'5.10'}>5.10'</option>
                    <option value={'5.12'}>5.12'</option>
                    <option value={'6.0'}>6.0'</option>
                    <option value={'6.5'}>6.5'</option>
                    <option value={'6.10'}>6.10'</option>
                    <option value={'6.12'}>6.12'</option>
                    <option value={'7.0'}>7.0'</option>

                    
                    </select>
                        
                </td>
            </tr>
            <tr>
                <td>Weight : </td>
                <td>
                <select  class='inp form-control' name='weight' value={weight} id="weight" onChange={e=>handlechange(e)}>
                    <option value={''}> Please Select</option>
                    <option value={'40'}>40 kg</option>
                    <option value={'41'}>41 kg</option>
                    <option value={'42'}>42 kg</option>
                    <option value={'43'}>43 kg</option>
                    <option value={'44'}>44 kg'</option>
                    <option value={'45'}>45 kg</option>
                    <option value={'46'}>46 kg</option>
                    <option value={'47'}>47 kg</option>
                    <option value={'48'}>48 kg</option>
                    <option value={'49'}>49 kg</option>
                    <option value={'50'}>50 kg</option>
                    <option value={'51'}>51 kg</option>
                    <option value={'52'}>52 kg</option>
                    <option value={'53'}>53 kg</option>
                    <option value={'54'}>54 kg</option>
                    <option value={'55'}>55 kg</option>
                    <option value={'56'}>56 kg</option>
                    <option value={'57'}>57 kg</option>
                    <option value={'58'}>58 kg</option>
                    <option value={'59'}>59 kg</option>
                    <option value={'60'}>60 kg</option>
                    <option value={'61'}>61 kg</option>
                    <option value={'62'}>62 kg</option>
                    <option value={'63'}>63 kg</option>
                    <option value={'64'}>64 kg</option>
                    <option value={'65'}>65 kg</option>
                    <option value={'66'}>66 kg</option>
                    <option value={'67'}>67 kg</option>
                    <option value={'68'}>68 kg</option>
                    <option value={'69'}>69 kg</option>
                    <option value={'70'}>70 kg</option>
                    <option value={'71'}>71 kg</option>
                    <option value={'72'}>72 kg</option>
                    <option value={'73'}>73 kg</option>
                    <option value={'74'}>74 kg</option>
                    <option value={'75'}>75 kg</option>
                    <option value={'76'}>76 kg</option>
                    <option value={'77'}>77 kg</option>
                    <option value={'78'}>78 kg</option>
                    <option value={'79'}>79 kg</option>
                    <option value={'80'}>80 kg</option>

                    
                    </select>
                        
                </td>
            </tr>
            <tr>
                <td>Color : </td>
                <td>
                    <select  class='inp form-control' name='color' value={color} id="color" onChange={e=>handlechange(e)}>
                    <option value={''}>Please Select</option>
                    <option value={'Fair'}>Fair</option>
                    <option value={'Olive'}>Olive</option>
                    <option value={'Medium'}>Medium</option>
                    <option value={'Deep'}>Deep</option>
                    </select>
                    </td>
            </tr>
            <tr>
                <td>Income (LPA): </td>
                <td>
                <input type="number" class='inp form-control'  name='income' value={income}  onChange={e=>handlechange(e)}></input>

                    </td>
            </tr>
            <tr>
                <td>Marital Status : </td>
                <td>
                    <select  class='inp form-control' name='ms' value={ms} id="ms" onChange={e=>handlechange(e)}>
                    <option value={''}> Please Select</option>
                    <option value={'Never married'}>Never married</option>
                    <option value={'Married'}>Married</option>
                    <option value={'Awaiting Divorce'}>Awaiting Divorce</option>
                    <option value={'Widowed'}>Widowed</option>
                    </select>
                    </td>
            </tr>


            <tr>
                <td>Religion : </td>
                <td >
                    <select  class='inp form-control'name='religion' value={religion} id="rel" onChange={e=>handlechange(e)}>
                    <option value={''}>Please Select</option>
                    <option value={'Hindu'}>Hindu</option>
                    <option value={'Muslim'}>Muslim</option>
                    <option value={'Sikh'}>Sikh</option>
                    <option value={'Christian'}>Christian</option>
                    <option value={'Jain'}>Jain</option>
                    <option value={'Parsi'}>Parsi</option>
                    <option value={'Buddhist'}>Buddhist</option>


                    </select>
                    </td>
            </tr>
            <tr>
                <td> Jati: </td>
                <td>
                <input type="text" class='inp form-control'  name='jati' value={jati}  onChange={e=>handlechange(e)}></input>

                    </td>
            </tr>
            <tr>
                <td> Gotra: </td>
                <td>
                <input type="text" class='inp form-control'  name='gotra' value={gotra}  onChange={e=>handlechange(e)}></input>

                    </td>
            </tr>
            <tr>
                <td>Manglik : </td>
                <td>
                <select  class='inp form-control' name='manglik'  id="manglik" onChange={e=>handlechange(e)}>
                    <option value={'manglik'}>{manglik}</option>

                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                    </select>                    
                </td>
            </tr>



            <tr>
                <td>Mother Tongue : </td>
                <td >
                    <select  class='inp form-control' name='mt' value={mt} id="mt" onChange={e=>handlechange(e)}>
                    <option value={''}>Please Select</option>
                    <option value={'Hindi'}>Hindi</option>
                    <option value={'Gujrati'}>Gujrati</option>
                    <option value={'Bihari'}>Bihari</option>
                    <option value={'Panjabi'}>Panjabi</option>
                    <option value={'Rajisthani'}>Rajisthani</option>
                    <option value={'Panjabi'}>Panjabi</option>
                    <option value={'Haryanvi'}>Haryanvi</option>
                    <option value={'Tamil'}>Tamil</option>
                    <option value={'Telugu'}>Telugu</option>
                    <option value={'Malyalam'}>Malyalam</option>
                    <option value={'Kannad'}>Kannad</option>
                    <option value={'Bangali'}>Bangali</option>
                    <option value={'Oriya'}>Oriya</option>
                    <option value={'Urdu'}>Urdu</option>
                    <option value={'English'}>English</option>
                    </select>
                    </td>
            </tr>


            <tr>
                <td>Category : </td>
                <td>
                <select  class='inp form-control' name='cat'  id="cat" onChange={e=>handlechange(e)}>
                   
                    <option value={cat}>{cat}</option>
                    <option value={'Gen'}>General</option>
                    <option value={'OBC'}>OBC</option>
                    <option value={'ST'}>ST</option>
                    <option value={'SC'}>SC</option>
                    </select>


                </td>
            </tr>
            <tr>
                <td>Fathers Name: </td>
                <td>
                <input type="text" class='inp form-control'  name='fatherna' value={fatherna}  onChange={e=>handlechange(e)}></input>

                    </td>
            </tr>
            <tr>
                <td>Fathers Occupation : </td>
                <td>
                    <input type="text" class='inp form-control'  name='fatheroccu' value={fatheroccu}  onChange={e=>handlechange(e)}></input>
                        
                </td>
            </tr>

            <tr>
                <td>Mothers Name: </td>
                <td>
                <input type="text" class='inp form-control'  name='motherna' value={motherna}  onChange={e=>handlechange(e)}></input>

                    </td>
            </tr>
            <tr>
                <td>Mothers Occupation : </td>
                <td>
                    <input type="text" class='inp form-control'  name='motheroccu' value={motheroccu}  onChange={e=>handlechange(e)}></input>
                        
                </td>
            </tr>

            <tr>
                <td>Brother: </td>
                <td>
                <select  class='inp form-control' name='bro'  id="bro" onChange={e=>handlechange(e)}>
                   
                   <option value={"bro"}>{bro}</option>
                   <option value={'1'}>1</option>
                   <option value={'2'}>2</option>
                   <option value={'3'}>3</option>
                   <option value={'4'}>4</option>
                   <option value={'5'}>5</option>
                   </select>
                    </td>
            </tr>
            
            <tr>
                <td>Sister  : </td>
                <td>
                    <select  class='inp form-control' name='sis'  id="sis" onChange={e=>handlechange(e)}>
                   
                   <option value={"sis"}> {sis}</option>
                   <option value={'1'}>1</option>
                   <option value={'2'}>2</option>
                   <option value={'3'}>3</option>
                   <option value={'4'}>4</option>
                   <option value={'5'}>5</option>
                   </select>
                </td>
            </tr>
            <tr>
                <td>Contact Number : </td>
                <td><input type="number" class='form-control' name='cno' value={cno} id="cno" onChange={e=>handlechange(e)}></input></td>
            </tr>
            
            
            <tr>
                <td>Address : </td>
                <td><textarea class='form-control' name='addr' value={addr} id="addr" onChange={e=>handlechange(e)}></textarea></td>
            </tr>
           

            
            <tr>
                <td></td>
                <td><br/><input type="submit" class='btn btn-primary col-12' value={"Update"} /></td>
            </tr>
        </table>
        
        </form>

                                    
        </center>
    
    </>
}
export default Edituprofile;