import { useState} from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import Header from "../header";
import 'bootstrap-icons/font/bootstrap-icons.css';

function Ulogin() {
  const history=useNavigate()
  const [uname, setUname] = useState("")
  const [upass, setUpass] = useState("")
  
  const myshow=(e)=>
  {
    let ptype=document.getElementById('pass').type;
    if(ptype=='password')
    {
      document.getElementById('pass').type='text'
      document.getElementById('eye2').className='tebi bi-eye'
    }
    else{
      document.getElementById('pass').type='password'
      document.getElementById('eye2').className='bi bi-eye-slash'
      
    }
   
  }
  const checklogin = async(e) => {
    e.preventDefault()


    let mail = document.getElementById("email").value;
    let passw = document.getElementById("pass").value;
    
    if(mail==="")
    {
      alert("Please enter Mail Id :");
      document.getElementById("email").focus();
      return false;
    }
    if(passw==="")
    {
      alert("Please enter Password :");
      document.getElementById("pass").focus();
      return false;
    }
        



    const fdata = new FormData()
    fdata.append('uname', uname)
    fdata.append('upass', upass)

    await axios.post("http://localhost/matrimony/ulogin.php", fdata).then((res) => {
      if (res.data === 1) {
        sessionStorage.setItem('uid',uname)
        sessionStorage.setItem('upass',upass)

        alert('Logged successfully')
        history('/dash')

      }
      else {
        alert('Error in code')
      }
    })
  }

  return <>
  <body>
  <Header/>
    <center>
      <form onSubmit={e => checklogin(e)}>
        
<section class="vh-100" >
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" >
          <div class="card-body p-5 text-center">

            <h3 class="mb-5">Sign in</h3>

            <div class="form-outline mb-4">
              <div className="form-control form-control-lg">
              <input type="email" id="email"  onChange={e => setUname(e.target.value)} placeholder="Email" class="myinput" />
          
              </div>
            </div>

            <div class="form-outline mb-4">
              <div className="form-control form-control-lg">
              <input type='password' id="pass" onChange={e => setUpass(e.target.value)} placeholder="Password" class="myinput" />
           
           <button type="button" onClick={e=>myshow(e)} className="btn2"> <i id='eye2' class="bi bi-eye-slash"></i></button>
              </div>
           </div>

            <div class="form-check d-flex justify-content-start mb-4">
              <input class="form-check-input" type="checkbox" value="" id="form1Example3" />
              <label class="form-check-label" for="form1Example3"> Remember password </label>
            </div>

            <button class="btn btn-primary btn-lg btn-block w-100" type="submit"> Login </button>

            <div class="form-text text-center mb-5 text-dark">Not
              Registered? <a href="/register" class="text-dark fw-bold"> Create an
                Account</a>
            </div>


            

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      </form>

    </center>
    </body>
  </>
}
export default Ulogin;
