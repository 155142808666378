import Adminheader from "./admin_header";



function Adminhome(){
    
  function noBack()
  {
    window.history.forward();
  }
  return <>
  <body onLoad={noBack} onpageshow='if(event.persisted) noBack()' onunload='' >
    <Adminheader/>

        <h2>Home</h2>
    
    </body>
    </>
}
export default Adminhome;