import Adminheader from "./admin_header";
import { useState } from "react";
import axios from "axios";

function Resetapass(){

    const [nps, setNps] = useState("")
    const [cps, setCps] = useState("")
    const updatealogin = async(e) => {
        e.preventDefault()
        const fdata = new FormData()
        fdata.append('nps', nps)
        fdata.append('cps', cps)

        await axios.post("http://localhost/matrimony/resetapass.php", fdata).then((res) => {
        if (res.data === 1) {
          
          alert('Password Changed successfully ')
  
        }
        
        else if(res.data==2)
        {
            alert('Confirm Password Not match ')  
        }
        else {
          alert('Error in code')
        }
      })
    }

   
return<>
<Adminheader/>
<center>
    <form onSubmit={e => updatealogin(e)} >

<div class="card text-center resetpass" >
    <div class="card-header h5 text-white bg-primary">Reset Admin Password 🔒</div>
    <div class="card-body px-5">
       
        <div class="form-outline">
            <input type="text" onChange={e=>setNps(e.target.value)} id="nps" class="form-control my-3" />
            <label class="form-label" >Enter New Password</label>
            <input type="password"  onChange={e=>setCps(e.target.value)}  id="cps" class="form-control my-3" />
            <label class="form-label" >Confirm Password</label>


        </div>
        <input type='submit' value='Set New Password' class="btn btn-primary w-100"/>
        <div class="d-flex justify-content-between mt-4">
        </div>
    </div>
</div>
</form>
</center>

</>
}
export default Resetapass;