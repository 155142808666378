import { Link } from "react-router-dom";
import Uheader from "./uheader";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";


function Transaction(){

    let uid=sessionStorage.getItem('uid')
    const [reg, setReg] = useState([])
    const [plan, setPlan] = useState([])
	let history=useNavigate()

    
    useEffect(() => {
        loadreg();
    })
    
    const loadreg = async () => {
        const result = await axios.get("http://localhost/matrimony/uprofile.php?uid="+uid);
        setReg(result.data.records);
    }

     const {pid}=useParams()

     useEffect(() => {
        
        loadplan();
     })
    const loadplan = async () => {
       const result2 =await axios.get("http://localhost/matrimony/transaction.php?pid="+pid);
         setPlan(result2.data.records);
     }


	const [cardno, setCardno]=useState("")
    const [cvv, setCvv]=useState("")
    const [noc, setNoc]=useState("")

	const submitform=async(e)=>{

	e.preventDefault()
	const formdata=new FormData()
          formdata.append('cardno',cardno)
          formdata.append('cvv',cvv)
          formdata.append('noc',noc)
          formdata.append('uid',uid)
		  formdata.append('pid',pid)

           
			await axios.post("http://localhost/matrimony/transaction_sub.php",formdata).then((res)=>{
            if(res.data===1)
            {
                alert('Submitted successfully')
				history('/transsuccess')

            }
			else{
				alert('Error in code ')

			}
			})  
		}   


    return<>
    <Uheader/>

    
		<section>
			<div class="container">

				<div class="row g-4 g-lg-5">	

					<div class="col-xl-8">
						<div class="vstack gap-5">
                            

							<div class="card shadow">


                                

                            <div class="card-header border-bottom p-4">
									<h4 class="card-title mb-0"><i class="bi bi-people-fill me-2"></i>Personal Details</h4>
								</div>
                                    {reg.map((reg, index) => (


								<div class="card-body p-4">

									<div class="card mb-4">
										<div class="row align-items-center">

											<div class="col-sm-6 col-md-3">
												<img src="assets/images/category/hotel/4by3/02.jpg" class="card-img" alt="" />
											</div>

											<div class="col-sm-6 col-md-9">
												<div class="card-body pt-3 pt-sm-0 p-0">

													<h5 class="card-title">{reg.na}</h5>
													<p class="small mb-2"><i class="bi bi-geo-alt me-2"></i>{reg.addr}</p>
                                                    <p class="small mb-2"><i class="bi bi-telephone me-2"></i>{reg.cno}</p>
													<p class="small mb-2"><i class="bi bi-envelope me-2"></i>{reg.em}</p>

												</div>
											</div>
										</div>
									</div>
								</div>
                                    ))}

                                

							</div>

							

							<div class="card shadow">

								<div class="card-header border-bottom p-4">

									<h4 class="card-title mb-0"><i class="bi bi-wallet-fill me-2"></i>Payment Options</h4>
								</div>

								<div class="card-body p-4 pb-0">
									<div class="accordion accordion-icon accordion-bg-light" id="accordioncircle">

										<div class="accordion-item mb-3">
											<h6 class="accordion-header" id="heading-1">
												<button class="accordion-button rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
													<i class="bi bi-credit-card text-primary me-2"></i>	<span class="me-5">Credit or Debit Card</span>
												</button>
											</h6>
											<div id="collapse-1" class="accordion-collapse collapse show" aria-labelledby="heading-1" data-bs-parent="#accordioncircle">

												<div class="accordion-body">

													<div class="d-sm-flex justify-content-sm-between my-3">
														<h6 class="mb-2 mb-sm-0">We Accept:</h6>
														<ul class="list-inline my-0">
															<li class="list-inline-item"> <Link to="#"><img src="assets/images/element/visa.svg" class="h-30px" alt="" /></Link></li>
															<li class="list-inline-item"> <Link to="#"><img src="assets/images/element/mastercard.svg" class="h-30px" alt="" /></Link></li>
															<li class="list-inline-item"> <Link to="#"><img src="assets/images/element/expresscard.svg" class="h-30px" alt="" /></Link></li>
														</ul>
													</div>

													<form class="row g-3" onSubmit={e=>submitform(e)}>

														<div class="col-12">
															<label class="form-label"><span class="h6 fw-normal">Card Number *</span></label>
															<div class="position-relative">
																<input type="text" class="form-control" onChange={e=>setCardno(e.target.value)} maxlength="14" placeholder="XXXX XXXX XXXX XXXX"/>
																<img src="assets/images/element/visa.svg" class="w-30px position-absolute top-50 end-0 translate-middle-y me-2 d-none d-sm-block" alt=""/>
															</div>	
														</div>

														<div class="col-md-6">
															<label class="form-label"><span class="h6 fw-normal">Expiration date *</span></label>
															<div class="input-group">
																<input type="text" class="form-control" maxlength="2" placeholder="Month"/>
																<input type="text" class="form-control" maxlength="4" placeholder="Year"/>
															</div>
														</div>	

														<div class="col-md-6">
															<label class="form-label"><span class="h6 fw-normal"  >CVV / CVC *</span></label>
															<input type="text" class="form-control" maxlength="3" onChange={e=>setCvv(e.target.value)} placeholder="xxx"/>
														</div>

														<div class="col-12">
															<label class="form-label"><span class="h6 fw-normal"  >Name on Card *</span></label>
															<input type="text" class="form-control" aria-label="name of card holder" onChange={e=>setNoc(e.target.value)} placeholder="Enter card holder name"/>
														</div>

														<div class="col-12">
															<div class="d-sm-flex justify-content-sm-between align-items-center">
																<button type="submit" class="btn btn-primary mb-0">Pay Now</button>
															</div>
														</div>

													</form>

												</div>
											</div>
										</div>

										<div class="accordion-item mb-3">
											<h6 class="accordion-header" id="heading-2">
												<button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
													<i class="bi bi-globe2 text-primary me-2"></i> <span class="me-5">Pay with Net Banking</span>
												</button>
											</h6>
											<div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordioncircle">

												<div class="accordion-body">

													<form class="row g-3 mt-1">

														<ul class="list-inline mb-0">

															<li class="list-inline-item"> <h6 class="mb-0">Popular Bank:</h6> </li>

															<li class="list-inline-item">
																<input type="radio" class="btn-check" name="options" id="option1"/>
																<label class="btn btn-light btn-primary-soft-check" for="option1">
																	<img src="assets/images/element/13.svg" class="h-20px me-2" alt="" />Bank of America
																</label>
															</li>

															<li class="list-inline-item">
																<input type="radio" class="btn-check" name="options" id="option2"/>
																<label class="btn btn-light btn-primary-soft-check" for="option2">
																	<img src="assets/images/element/15.svg" class="h-20px me-2" alt="" />Bank of Japan
																</label>
															</li>

															<li class="list-inline-item">
																<input type="radio" class="btn-check" name="options" id="option3"/>
																<label class="btn btn-light btn-primary-soft-check" for="option3">
																	<img src="assets/images/element/14.svg" class="h-20px me-2" alt="" />VIVIV Bank
																</label>
															</li>
														</ul>

														<p class="mb-1">In order to complete your transaction, we will transfer you over to Booking secure servers.</p>
														<p class="my-0">Select your bank from the drop-down list and click proceed to continue with your payment.</p>

														<div class="col-md-6">
															<select class="form-select form-select-sm js-choice border-0">
																<option value="">Please choose one</option>
																<option>Bank of America</option>
																<option>Bank of India</option>
																<option>Bank of London</option>
															</select>
														</div>

														<div class="d-grid">
															<button class="btn btn-success mb-0">Pay $1800</button>
														</div>

													</form>

												</div>
											</div>
										</div>

										<div class="accordion-item mb-3">
											<h6 class="accordion-header" id="heading-3">
												<button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
													<i class="bi bi-paypal text-primary me-2"></i><span class="me-5">Pay with Paypal</span>
												</button>
											</h6>
											<div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordioncircle">

												<div class="accordion-body">
													<div class="card card-body border align-items-center text-center mt-4">

														<img src="assets/images/element/paypal.svg" class="h-70px mb-3" alt="" />
														<p class="mb-3"><strong>Tips:</strong> Simply click on the payment button below to proceed to the PayPal payment page.</p>
														<Link to="#" class="btn btn-sm btn-outline-primary mb-0">Pay with paypal</Link>
													</div>
												</div>
											</div>
										</div>

									</div>

								</div>	

								<div class="card-footer p-4 pt-0">

									<p class="mb-0">By processing, You accept Booking <Link to="#">Terms of Services</Link> and <Link to="#">Policy</Link></p>
								</div>
							</div>


						</div>	
					</div>

					<aside class="col-xl-4">
						<div class="row g-4">

							<div class="col-md-6 col-xl-12">
								<div class="card shadow rounded-2">

									<div class="card-header border-bottom">
										<h5 class="card-title mb-0">Price Summary</h5>
									</div>
                                        {plan.map((plan, index) => (

                                    <div>
									<div class="card-body">
										<ul class="list-group list-group-borderless">
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<span class="h6 fw-light mb-0">Plan Type</span>
												<span class="fs-5">{plan.ptype}</span>
											</li>
											
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<span class="h6 fw-light mb-0">Price</span>
												<span class="fs-5">${plan.pprice}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<span class="h6 fw-light mb-0">GST 18% Fees</span>
												{
													(()=>{
														let gst=plan.pprice*18/100;
											
												
														return<>												
														<span class="fs-5 mb-0">${gst}</span>
														</>
													})()
												}
												
											</li>
										</ul>
									</div>
                                      
									<div class="card-footer border-top">
										<div class="d-flex justify-content-between align-items-center">
											<span class="h5 mb-0">Payable Amount</span>
											{
													(()=>{
														let gst1=(plan.pprice)*18/100;
											
														let tamt1=parseInt(plan.pprice)+parseInt(gst1);
														return<>												
														<span class="h5 mb-0">${tamt1}</span>
														</>
													})()
												}
											
										</div>
									</div>
									</div>
									  ))}
								</div>
							</div>

						</div>
					</aside>

				
                </div> 

			</div>
		</section>
				
    
    </>
}

export default Transaction;