import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate} from "react-router-dom";

function Editplan(){

    const {uid}=useParams()
    let history=useNavigate()
    useEffect(() => {
        loadplan1();
    },[])
    
    const loadplan1 = async () => {
        const result = await axios.get("http://localhost/matrimony/edit_plan.php?id="+uid);
        setPlan1(result.data);
    }

     const [plan1, setPlan1 ]= useState({
       id:"",
       ptype:"",
       pprice:"",
       pdesc:"",
       
    })

    const {id, ptype, pprice, pdesc}=plan1;
    const handlechange=(e)=>{
        setPlan1({...plan1,[e.target.name]:e.target.value});
    }
    
    const updateform=async(e)=>{
        e.preventDefault()
        let plan2=JSON.stringify(plan1);
        await axios.post("http://localhost/matrimony/update_plan.php",plan2).then((res)=>{
          if(res.data===1)
          {
              alert('Updated successfully')
              history('/createplan')
          }
          else{
              alert('Error in code')
          }
        })     

  }


return<>

<title>Matrimonial</title>
		<div class="has-navbar-mobile">
        <main>		
        <section class="vh-xxl-100">
				<div class="container px-0 px-sm-4">
					<div class="row justify-content-center align-items-center m-auto">
						<div class="col-12">
                        
							<div class="bg-mode shadow rounded-3 overflow-hidden">
								<div class="row g-0">									
									<div class="col-lg-7 mx-auto">
										<div class="p-3 p-sm-4">
											
											<h1 class="mb-2 h3">Edit User Plan</h1>
					
											<form class="mt-4 text-start" onSubmit={e=>updateform(e)} >
												<div class="row">
													
													<div class="col-md-6 col-sm-12 mb-3">
														<select class="form-select js-choice" name="ptype" value={ptype} onChange={e=>handlechange(e)} >
															<option value="" disabled selected>Select Plan</option>
															<option>Gold</option>
															<option>Platinum</option>
															<option>Daimond</option>
														</select>
													</div>
													<div class="col-md-6 col-sm-12 mb-3">
														<input type="text" class="form-control" name="pprice" value={pprice} placeholder="Enter Price" onChange={e=>handlechange(e)} />
													</div>
													<div class="col-md-12 col-sm-12 mb-3">
														<textarea name="pdesc" class="form-control" value={pdesc} placeholder="Enter Plan Description" onChange={e=>handlechange(e)} ></textarea>
													</div>
													
													<div><input type="submit" class="btn btn-primary w-100 mb-0" /></div>

												</div>	
											</form>
										</div>		
									</div>
								</div>
							</div>
						</div>
                        
					</div>
				</div>
			</section>
    </main>
    </div> 
    </>
}
export default Editplan;