import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './login';
import './style.css';
import './style2.css';
import Home from './home';
import Adminhome from './admin/admin_home';
import Register from './register';
import Ulogin from './upannel/ulogin';
import Dash from './upannel/udash';
import Ulogout from './upannel/ulogout';
import Admin_logout from './admin/admin_logout';
import Uprofile from './upannel/uprofile';
import Edituprofile from './upannel/edituprofile';
import Profileimgedit from './upannel/profileimgedit';
import Resetupass from './upannel/resetupass';
import Resetapass from './admin/resetapass';
import Userdetail from './admin/user_detail';
import Viewuser from './admin/view_user';
import Search from './search';
import Searchdisplay from './searchdisplay';
import Createplan from './admin/createplan';
import Plan from './plan';
import Editplan from './admin/edit_plan';
import View_details from './upannel/view_details';
import Transaction from './upannel/transaction';
import Transsuccess from './upannel/transsuccess';
import View_user_plan from './admin/view_user_plan';
function App() {
  return<>
<Router>
  <Routes>
    <Route path='/' element={<Home/>}></Route>
    <Route path='/ulogin' element={<Ulogin/>}></Route>

    <Route path='/login' element={<Login/>}></Route>
    <Route path='/admin_home' element={<Adminhome/>}></Route>
    <Route path='/admin_logout' element={<Admin_logout/>}></Route>
    <Route path='/resetapass' element={<Resetapass/>}></Route>
    <Route path='/user_detail' element={<Userdetail/>}></Route>
    <Route path='/view_user/:uid' element={<Viewuser/>}></Route>
    
    <Route path='/register' element={<Register/>}></Route>
    <Route path='/ulogin' element={<Ulogin/>}></Route>
    <Route path='/dash' element={<Dash/>}></Route>
    <Route path='/ulogout' element={<Ulogout/>}></Route>
    <Route path='/uprofile' element={<Uprofile/>}></Route>
    <Route path='/edituprofile/:uid' element={<Edituprofile/>}></Route>
    <Route path='/resetupass/' element={<Resetupass/>}></Route>

    <Route path='/profileimgedit/' element={<Profileimgedit/>}></Route>
    <Route path='/search' element={<Search/>}></Route>
    <Route path='/searchdisplay' element={<Searchdisplay/>}></Route>
    <Route path='/plan/' element={<Plan/>}></Route>
    <Route path='/createplan' element={<Createplan/>}></Route>
    <Route path='/edit_plan/:uid' element={<Editplan/>}></Route>
    <Route path='/view_details/:uid' element={<View_details/>}></Route>
    <Route path='/transaction/:pid' element={<Transaction/>}></Route>
    <Route path='/transsuccess/' element={<Transsuccess/>}></Route>
    <Route path='/view_user_plan/:uid' element={<View_user_plan/>}></Route>

    
    
    
    
    





  </Routes>
</Router>
  </>
}

export default App;
