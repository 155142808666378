import { Link} from "react-router-dom";

function Admin_logout(){
    sessionStorage.setItem("un",'');
    sessionStorage.setItem("ps",'');
    return<>

    <center>
        <h3>Your session has been expired </h3>
        <br/>
        <Link to='/login'>Click Here To Again Login...</Link>
    </center>
    
    </>
}
export default Admin_logout;