import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

function Header() {
  return <>
  <center>
    <nav class="navbar navbar-expand-lg hd fixed-top"><Link class="nav-link active" aria-current="page" to="/"> <img src='/img/logo.png' width={280} height={80} /></Link>
      <div class="container-fluid">
        
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/">Home</Link>
            </li>
          
            <li class="nav-item">
              <Link class="nav-link" to="/plan">Plan</Link>
            </li>
            
            <li class="nav-item">
              <Link class="nav-link" to="/search"> Search </Link>
            </li>  
            <li class="nav-item">
              <Link class="nav-link" to="/contact"> Contact </Link>
            </li>            
          </ul>
        </div>
        <form class="d-flex" role="search">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  
            <li class="nav-item me-5" >
              <Link class="nav-link" to="/ulogin" className='btn btn-primary'>Login</Link>
            </li>
            
            <li class="nav-item me-5">
          <Link class="nav-link" to="/register" className='btn btn-primary'>Register</Link>
            </li> 
            </ul>
      </form>
      </div>
    </nav>
    </center>
  </>
}
export default Header;