import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import Uheader from "./uheader"

function Profileimgedit(){
     
    let uid1=sessionStorage.getItem("uid");
    const [img, setImg]=useState("")
    const history=useNavigate()
    const updateform=async(e)=>{
        e.preventDefault()
        const fdata = new FormData()
        fdata.append('img',img);
        fdata.append('uid',uid1);
        await axios.post("http://localhost/matrimony/profileimgedit.php",fdata).then((res)=>{
            if(res.data===1){
                alert("Update successfuly");
                history('/uprofile')
            }
            else{
                alert("error in code");
            }
        })

    }
    return<>
    <Uheader/>


    <center>
        <form onSubmit={e=>updateform(e)}>
    <div class='container mt-5'>
        <div class='row'>
            <div class='col-3'></div>
           <div class='col-6'>
            <h3>Edit Profile Pic </h3>
            <div class='row mt-3'>
                <div class='col-12'>
                    <input type="file" class='form-control' onChange={e=>setImg(e.target.files[0])}/>
                </div>
            </div>
            
            <div class='row mt-3'>
                <div class='col-12'>
                    <input type="submit" name="sub" class='btn btn-primary' value={"Update"}/>
                </div>
            </div>
           </div>
        </div>
    </div>
    </form>
    </center> 

    </>

}
export default Profileimgedit;