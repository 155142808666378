function User_autho()
{
    let un=sessionStorage.getItem("uid")
    let ps=sessionStorage.getItem("upass")

    if(un!="" && ps!="")
    {

    }
    else{
        window.location='/ulogout';
    }

    return<>
    </>
}
export default User_autho;