import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function Viewuser(){

    const {uid}=useParams()

    const [mydata, setRegister] = useState([])
    useEffect(() => {
        loadreg();
    })
    
    const loadreg = async () => {
        const result = await axios.get("http://localhost/matrimony/view_user_by_id.php?uid="+uid);
        setRegister(result.data.records);
    }
    const imgurl="http://localhost/matrimony/upload/"

    return<>
<center>
            <form>
                <div class='container mt-5'>
                    <div class='row'>
                        <div class='col-3'></div>
                        <div class='col-12'>
                            <h3> Profile</h3>


                            <div class="card border">
                                    <div class="card-header border-bottom">
                                        <h4 class="card-header-title">Personal Information</h4>
                                    </div>

                                    <div class="card-body">
                                    {mydata.map((mydata, index) => (

                                        <form class="row g-3">
                                            <div class="col-12">
                                            <center>
                                                <img src={imgurl+mydata.img} className="profileimg me-3"/>
                                            </center>
                                            </div>
                                            
                                            
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Full Name</label>                                                
                                                <label class="text-dark">{mydata.na}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">E-mail id</label>                                                
                                                <label class="text-dark">{mydata.em}</label>                                                
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Gender</label>                                                
                                                <label class="text-dark">{mydata.gen}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Create profile for</label>                                                
                                                <label class="text-dark">{mydata.prof}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Date of birth</label>                                                
                                                <label class="text-dark">{mydata.dob}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Education</label>                                                
                                                <label class="text-dark">{mydata.edu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Occupation  </label>                                                
                                                <label class="text-dark">{mydata.occu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Height</label>                                                
                                                <label class="text-dark">{mydata.height}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Weight</label>                                                
                                                <label class="text-dark">{mydata.weight}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Color</label>                                                
                                                <label class="text-dark">{mydata.color}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Income</label>                                                
                                                <label class="text-dark">{mydata.income}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Marital status</label>                                                
                                                <label class="text-dark">{mydata.ms}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Religion</label>                                                
                                                <label class="text-dark">{mydata.religion}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Jati</label>                                                
                                                <label class="text-dark">{mydata.jati}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Gotra</label>                                                
                                                <label class="text-dark">{mydata.gotra}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Manglik</label>                                                
                                                <label class="text-dark">{mydata.manglik}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mother Tongue</label>                                                
                                                <label class="text-dark">{mydata.mt}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Category</label>                                                
                                                <label class="text-dark">{mydata.cat}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Fathers Name</label>                                                
                                                <label class="text-dark">{mydata.fatherna}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Fathers Occupation</label>                                                
                                                <label class="text-dark">{mydata.fatheroccu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mothers Name</label>                                                
                                                <label class="text-dark">{mydata.motherna}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mothers Occupation</label>                                                
                                                <label class="text-dark">{mydata.motheroccu}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Brother </label>                                                
                                                <label class="text-dark">{mydata.bro}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3"> Sister</label>                                                
                                                <label class="text-dark">{mydata.sis}</label>                                                
                                            </div>

                                            <div class="col-md-6">
                                                <label class="form-label me-3">Mobile Number</label>                                                
                                                <label class="text-dark">{mydata.cno}</label>                                                
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label me-3">Address</label>                                                
                                                <label class="text-dark">{mydata.addr}</label>                                                
                                            </div>
                                                            
                                        </form>
                                    ))}

                                    </div>
                                </div>   

                        </div>
                    </div>
                </div>
            </form>
        </center>
    
    </>
}
export default Viewuser;