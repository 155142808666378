function Footer(){
    return<>
<footer class="text-center text-lg-start bg-white text-muted">
  <div className="container">
  <div class="row justify-content-center justify-content-lg-between p-4 border-bottom">
    <div class="col-md-6">
      <span>Get connected with us on social networks:</span>
    </div>
    <div className="col-md-6 text-end">
      <a href="" class="me-4 link-secondary">
      <i class="bi bi-facebook"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="bi bi-twitter"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="bi bi-google"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="bi bi-instagram"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="bi bi-linkedin"></i>
      </a>
      <a href="" class="me-4 link-secondary me-5">
        <i class="bi bi-github"></i>
      </a>
    </div>
  </div>
  </div>

  <section class="">
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3 text-secondary"></i>Company name
          </h6>
          <p>
          Matrimonial sites can be referred to as platforms designed to match two potential lovers for a successful relationship and marriage.
          </p>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Products
          </h6>
          <p>
            <a href="#!" class="text-reset">Angular</a>
          </p>
          <p>
            <a href="#!" class="text-reset">React</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Vue</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Laravel</a>
          </p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p><i class="bi bi-house me-3 text-secondary"></i>New York, NY 10012, US</p>
          <p>
            <i class="bi bi-envelope me-3 text-secondary"></i>
            info@example.com
          </p>
          <p><i class="bi bi-phone me-3 text-secondary"></i>+ 01 234 567 88</p>
          <p><i class="bi bi-printer me-3 text-secondary"></i>+ 01 234 567 89</p>
        </div>
      </div>
    </div>
  </section>
  <div class="text-center p-4 footer" >
    © 2023 Copyright
  </div>
</footer>
</>
}
export default Footer;