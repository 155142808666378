import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Register(){

    const history=useNavigate()


    const [na, setName]=useState("")
    const [em, setEmail]=useState("")
    const [pass, setPass]=useState("")
    const [cpass, setCpass]=useState("")

    const [gen, setGen]=useState("")
    const [prof, setProf]=useState("")
    const [dd, setDd]=useState("")
    const [mm, setMm]=useState("")
    const [yy, setYy]=useState("")

    const [ms, setMs]=useState("")
    const [religion, setReligion]=useState("")
    const [manglik, setManglik]=useState("")
    const [mt, setMt]=useState("")
    const [cat, setCat]=useState("")
    const [cno, setCno]=useState("")
    const [add, setAdd]=useState("")

        const submitform=async(e)=>{
          
        e.preventDefault()
        let uname = document.getElementById("name").value;
        let passw = document.getElementById("pass").value;
        let mail = document.getElementById("email").value;
        let prof = document.getElementById("prof").value;
        let dd = document.getElementById("dd").value;
        let mm = document.getElementById("mm").value;
        let yy = document.getElementById("yy").value;
        let ms = document.getElementById("ms").value;
        let rel = document.getElementById("rel").value;
        let addr = document.getElementById("addr").value;
        let cno = document.getElementById("cno").value;


            if(uname==="")
                {
                    alert("Please enter user name :");
                    document.getElementById("name").focus();
                    return false;
                }
            
            if(mail==="")
                {
                    alert("Please enter Mail Id :");
                    document.getElementById("email").focus();
                    return false;
                }
            
            if(passw==="")
                {
                    alert("Please enter Password :");
                    document.getElementById("pass").focus();
                    return false;
                }
        
            if(passw.length<8)
                {
                    alert("Password should be minimum 8 charater :");
                    document.getElementById("passw").focus();
                    return false;
                }
            if(passw.length>16)
                {
                    alert("Password should be maxmum 16 charater :");
                    document.getElementById("passw").focus();
                    return false;
                }

            if(passw.match(/^[a-zA-Z0-9]+$/))
                {
                    alert("Password should be alphanumeric with special character :");
                    document.getElementById("passw").focus();
                    return false;
                }
            if(document.getElementById("gen1").checked===false && document.getElementById("gen2").checked===false)
                {
                    alert("Please select gender :");
                    document.getElementById("gen1").focus();
                    return false;
                }
            
            if(prof==="")
                {
                    alert("Please select profile for :");
                    document.getElementById("prof").focus();
                    return false;
                }
            if(dd==="")
                {
                    alert("Please select Date :");
                    document.getElementById("dd").focus();
                    return false;
                }
            if(mm==="")
                {
                    alert("Please select Month :");
                    document.getElementById("mm").focus();
                    return false;
                }
            if(yy==="")
                {
                    alert("Please select Year :");
                    document.getElementById("yy").focus();
                    return false;
                }
            if(ms==="")
                {
                    alert("Please select Marital Status :");
                    document.getElementById("ms").focus();
                    return false;
                }
            if(rel==="")
                {
                    alert("Please select Religion :");
                    document.getElementById("rel").focus();
                    return false;
                }
            if(document.getElementById("mang1").checked===false && document.getElementById("mang2").checked===false)
                {
                    alert("Please select Manglik or not :");
                    document.getElementById("mang1").focus();
                    return false;
                }


            if(mt==="")
                {
                    alert("Please Enter Mother Tongue :");
                    document.getElementById("mt").focus();
                    return false;
                }


            if(document.getElementById("cat1").checked===false && document.getElementById("cat2").checked===false && document.getElementById("cat3").checked===false && document.getElementById("cat4").checked===false && document.getElementById("cat5").checked===false)
                {
                    alert("Please select Category :");
                    document.getElementById("cat1").focus();
                    return false;
                }

            if(cno==="")
                {
                    alert("Please Enter Contact Number :");
                    document.getElementById("cno").focus();
                    return false;
                }
            
            if(cno.length<10)
                {
                    alert("Number should be minimum 10 charater :");
                    document.getElementById("cno").focus();
                    return false;
                }
            if(cno.length>12)
                {
                    alert("Number should be maxmum 12 charater :");
                    document.getElementById("cno").focus();
                    return false;
                }
    
            if(addr==="")
                {
                    alert("Please enter Address :");
                    document.getElementById("addr").focus();
                    return false;
                }
            
            
            



          const formdata=new FormData()
          formdata.append('na',na)
          formdata.append('em',em)
          formdata.append('pass',pass)
          formdata.append('cpass',cpass)
          formdata.append('gen',gen)
          formdata.append('prof',prof)
          formdata.append('dd',dd)
          formdata.append('mm',mm)
          formdata.append('yy',yy)
          formdata.append('ms',ms)
          formdata.append('religion',religion)
          formdata.append('manglik',manglik)
          formdata.append('mt',mt)
          formdata.append('cat',cat)
          formdata.append('cno',cno)
          formdata.append('add',add)

          await axios.post("http://localhost/matrimony/register.php",formdata).then((res)=>{
            if(res.data===1)
            {
                alert('Submitted successfully')
                history('/ulogin')
            }
            else if(res.data===3)
            {
                alert('User already registered')
            }
            else if(res.data===2)
            {
                alert('Confirm password not matched ')
            }
            else 
            {
                alert('Error in code')
            }
          })     

    }
    
    return<>
    <body className="bg1">
        <Header/>

    <center >
        <div className="container">
            <div className="row">
                <div className="col-7">

                </div>
                <div className="col-5">
                    
            <div className="reg">
            <br></br> <h3>Sign Up </h3>
       <form onSubmit={e=>submitform(e)}>
    
        <table>
            <tr>
                <td>Name : </td>
                <td><input type="text" class='form-control' name='na' id="name" onChange={e=>setName(e.target.value)}></input></td>
            </tr>
            <tr>
                <td>Email Id : </td>
                <td><input type="email" class='form-control' name='em' id="email" onChange={e=>setEmail(e.target.value)}></input></td>
            </tr>
            <tr>
            <td>Password : </td>
            <td><input type='password' class='form-control' name='pass' id="pass" onChange={e=>setPass(e.target.value)}></input></td>
          </tr>
          <tr>
            <td>Confirm Password : </td>
            <td><input type='password' class='form-control' name='cpass' id="cpass" onChange={e=>setCpass(e.target.value)}></input></td>
          </tr>
            
            <tr>
                <td>Gender : </td>
                <td>
                    <input type="radio" name="gen1" id="gen1" class='ms-2' value={'Male'} onChange={e=>setGen(e.target.value)}/> Male
                    <input type="radio" name="gen1" id="gen2" class='ms-2' value={'Female'} onChange={e=>setGen(e.target.value)}/> Female
                    </td>
            </tr>
            <tr>
                <td>Create Profile For : </td>
                <td>
                    <select  class='inp form-control' name='prof' id="prof" onChange={e=>setProf(e.target.value)}>
                    <option value={''}>Please Select</option>
                    <option value={'Self'}>Self</option>
                    <option value={'Son'}>Son</option>
                    <option value={'Daughter'}>Daughter</option>
                    <option value={'Sister'}>Sister</option>
                    <option value={'Brother'}>Brother</option>
                    </select>
                    </td>
            </tr>
            
            <tr>
                <td>Date of birth : </td>
                <td>
                    <select  class='inp'  name='dob' id="dd" onChange={e=>setDd(e.target.value)}>
                    <option value={''}>DD</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                    <option value={13}>13</option>
                    <option value={14}>14</option>
                    <option value={15}>15</option>
                    <option value={16}>16</option>
                    <option value={17}>17</option>
                    <option value={18}>18</option>
                    <option value={19}>19</option>
                    <option value={20}>20</option>
                    <option value={21}>21</option>
                    <option value={22}>22</option>
                    <option value={23}>23</option>
                    <option value={24}>24</option>
                    <option value={25}>25</option>
                    <option value={26}>26</option>
                    <option value={27}>27</option>
                    <option value={28}>28</option>
                    <option value={29}>29</option>
                    <option value={30}>30</option>
                    <option value={31}>31</option>
                    </select>
                    <select  class='inp' id="mm" onChange={e=>setMm(e.target.value)}>
                    <option value={''}>MM</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                    </select>
                    <select  class='inp' id="yy" onChange={e=>setYy(e.target.value)}>
                    <option value={''}>YY</option>
                    <option value={1990}>1990</option>
                    <option value={1991}>1991</option>
                    <option value={1992}>1992</option>
                    <option value={1993}>1993</option>
                    <option value={1994}>1994</option>
                    <option value={1995}>1995</option>
                    <option value={1996}>1996</option>
                    <option value={1997}>1997</option>
                    <option value={1998}>1998</option>
                    <option value={1999}>1999</option>
                    <option value={2000}>2000</option>
                    <option value={2001}>2001</option>
                    <option value={2002}>2002</option>
                    <option value={2003}>2003</option>
                    <option value={2004}>2004</option>
                    <option value={2005}>2005</option>
                    </select>
                    </td>
            </tr>
            <tr>
                <td>Marital Status : </td>
                <td>
                    <select  class='inp form-control' name='ms' id="ms" onChange={e=>setMs(e.target.value)}>
                    <option value={''}> Please Select</option>
                    <option value={'Never married'}>Never married</option>
                    <option value={'Married'}>Married</option>
                    <option value={'Awaiting Divorce'}>Awaiting Divorce</option>
                    <option value={'Widowed'}>Widowed</option>
                    </select>
                    </td>
            </tr>


            <tr>
                <td>Religion : </td>
                <td >
                    <select  class='inp form-control'name='religion' id="rel" onChange={e=>setReligion(e.target.value)}>
                    <option value={''}>Please Select</option>
                    <option value={'Hindu'}>Hindu</option>
                    <option value={'Muslim'}>Muslim</option>
                    <option value={'Sikh'}>Sikh</option>
                    <option value={'Christian'}>Christian</option>
                    <option value={'Jain'}>Jain</option>
                    <option value={'Parsi'}>Parsi</option>
                    <option value={'Buddhist'}>Buddhist</option>


                    </select>
                    </td>
            </tr>

            <tr>
                <td>Manglik : </td>
                <td>
                    <input type="radio" name="manglik" id='mang1' onChange={e=>setManglik(e.target.value)} class='ms-2' value={'Yes'}/> Yes
                    <input type="radio" name="manglik" id='mang2' onChange={e=>setManglik(e.target.value)} class='ms-2' value={'No'}/> No
                    </td>
            </tr>



            <tr>
                <td>Mother Tongue : </td>
                <td >
                    <select  class='inp form-control' name='mt' id="mt" onChange={e=>setMt(e.target.value)}>
                    <option value={''}>Please Select</option>
                    <option value={'Hindi'}>Hindi</option>
                    <option value={'Gujrati'}>Gujrati</option>
                    <option value={'Bihari'}>Bihari</option>
                    <option value={'Panjabi'}>Panjabi</option>
                    <option value={'Rajisthani'}>Rajisthani</option>
                    <option value={'Panjabi'}>Panjabi</option>
                    <option value={'Haryanvi'}>Haryanvi</option>
                    <option value={'Tamil'}>Tamil</option>
                    <option value={'Telugu'}>Telugu</option>
                    <option value={'Malyalam'}>Malyalam</option>
                    <option value={'Kannad'}>Kannad</option>
                    <option value={'Bangali'}>Bangali</option>
                    <option value={'Oriya'}>Oriya</option>
                    <option value={'Urdu'}>Urdu</option>
                    <option value={'English'}>English</option>
                    </select>
                    </td>
            </tr>


            <tr>
                <td>Category : </td>
                <td>
                    <input type="radio" name="cat" id='cat1' onChange={e=>setCat(e.target.value)}  value={'GEN'}/> GEN
                    <input type="radio" name="cat" id='cat2' onChange={e=>setCat(e.target.value)} class="ms-2" value={'OBC'}/> OBC
                    <input type="radio" name="cat" id='cat3' onChange={e=>setCat(e.target.value)} class="ms-2" value={'SC'}/> SC
                    <input type="radio" name="cat" id='cat4' onChange={e=>setCat(e.target.value)} class="ms-2" value={'ST'}/> ST
                    <input type="radio" name="cat" id='cat5' onChange={e=>setCat(e.target.value)} class="ms-2" value={'Other'}/> Other

                    </td>
            </tr>
            <tr>
                <td>Contact Number : </td>
                <td><input type="number" class='form-control' name='cno' id="cno" onChange={e=>setCno(e.target.value)}></input></td>
            </tr>
            
            
            <tr>
                <td>Address : </td>
                <td><textarea class='form-control' name='add' id="addr" onChange={e=>setAdd(e.target.value)}></textarea></td>
            </tr>
           

            
            <tr>
                <td></td>
                <td><br/><button type="submit" class='btn btn-primary   col-12' > REGISTER FREE</button></td>
            </tr>
        </table>
        
        </form>
        </div>
                </div>
            </div>
            </div>
     
    </center>
    <Footer/>
    </body>
    </>
}
export default Register;