import { Link} from "react-router-dom";

function Ulogout(){
    sessionStorage.setItem('uid','')
    sessionStorage.setItem('upass','')
    return<>

    <center>
        <h3>Your session has been expired </h3>
        <br/>
        <Link to='/ulogin'>Click Here To Again Login...</Link>
    </center>
    
    </>
}
export default Ulogout;