import Header from "./header";
import { useState } from "react";
import axios from "axios";

function Search() {

    const [gen, setGen]=useState("")
    const [religion, setReligion]=useState("")
    const [age, setAge]=useState("")
    const [age1, setAge1]=useState("")

    const submitform=async(e)=>{
          
        e.preventDefault()
        sessionStorage.setItem('gen',gen)
        sessionStorage.setItem('religion',religion)
        sessionStorage.setItem('age',age)
        sessionStorage.setItem('age1',age1)
        window.location='/searchdisplay';
        }
  return <>
<Header/>

    <center>
      <form onSubmit={e=>submitform(e)}>
        
<section class="vh-100" >
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" >
          <div class="card-body p-5 text-center">
            

          <table>

            <div class="form-outline mb-4">
            <h2 class="text-primary">Begin Your Search for an Ideal Match</h2>
              
               
            <tr>
                <td width={102}>
                    
                <label class="form-label me-3 card-body">Age</label> 
                </td>
            <td width={105}>
            <select  class='inp form-control' name='mt' id="mt" onChange={e=>setAge(e.target.value)} >
                    <option value={''}>Select Age</option>
                    <option value={'18'}>18</option>
                    <option value={'19'}>19</option>
                    <option value={'20'}>20</option>
                    <option value={'21'}>21</option>
                    <option value={'22'}>22</option>
                    <option value={'23'}>23</option>
                    <option value={'24'}>24</option>
                    <option value={'25'}>25</option>
                    <option value={'26'}>26</option>
                    <option value={'27'}>27</option>
                    <option value={'28'}>28</option>
                    <option value={'29'}>29</option>
                    <option value={'30'}>30</option>
                    <option value={'31'}>31</option>
                    <option value={'32'}>32</option>
                    <option value={'33'}>33</option>
                    <option value={'34'}>34</option>
                    <option value={'35'}>35</option>

                    
                    </select>
                    </td>
                    <td width={41}> To </td>
                    <td width={105}>
                    <select  class='inp form-control' name='mt' id="mt" onChange={e=>setAge1(e.target.value)} >
                    <option value={''}>Select Age</option>
                    <option value={'18'}>18</option>
                    <option value={'19'}>19</option>
                    <option value={'20'}>20</option>
                    <option value={'21'}>21</option>
                    <option value={'22'}>22</option>
                    <option value={'23'}>23</option>
                    <option value={'24'}>24</option>
                    <option value={'25'}>25</option>
                    <option value={'26'}>26</option>
                    <option value={'27'}>27</option>
                    <option value={'28'}>28</option>
                    <option value={'29'}>29</option>
                    <option value={'30'}>30</option>
                    <option value={'31'}>31</option>
                    <option value={'32'}>32</option>
                    <option value={'33'}>33</option>
                    <option value={'34'}>34</option>
                    <option value={'35'}>35</option>
                    
                    </select>
                    </td>
                    </tr>
            </div>

            <div class="form-outline mb-4">
            <td width={50}>
                    
                    <label class="form-label me-3 card-body"> Gender </label> 
                    </td>
                <td width={320}>
              <select  class='inp form-control' onChange={e=>setGen(e.target.value)} >
                    <option value={''}>Please Select</option>
                    <option value={'male'}>Male</option>
                    <option value={'female'}>Female</option>
                    
                    </select>
                    </td>
            </div>
            <div class="form-outline mb-4">
            <td width={102}>
                    
                    <label class="form-label card-body">Religion</label> 
                    </td>
                    <td width={255}>
              <select  class='inp form-control' name='mt' id="mt" onChange={e=>setReligion(e.target.value)}>
              <option value={''}>Please Select</option>
                    <option value={'Hindu'}>Hindu</option>
                    <option value={'Muslim'}>Muslim</option>
                    <option value={'Sikh'}>Sikh</option>
                    <option value={'Christian'}>Christian</option>
                    <option value={'Jain'}>Jain</option>
                    <option value={'Parsi'}>Parsi</option>
                    <option value={'Buddhist'}>Buddhist</option>
                    </select>
                    </td>
            </div>
            
            </table>


            <button class="btn btn-primary btn-lg btn-block w-100" type="submit"> Search </button>    
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      </form>

    </center>
  </>
}
export default Search;
