import {Link} from 'react-router-dom';
import { useState,useEffect} from 'react';
import axios from 'axios';
import Adminheader from './admin_header';
function Createplan(){

    const [ptype, setPtype] = useState("")
    const [pprice, setPprice]=useState("")
    const [pdesc, setPdesc]=useState("")

    const submitform=async(e)=>
    {
        e.preventDefault()
        const formdata=new FormData()
        formdata.append('ptype',ptype)
        formdata.append('pprice',pprice)
        formdata.append('pdesc',pdesc)

        await axios.post("http://localhost/matrimony/createplan.php",formdata).then((res)=>{
            if(res.data===1)
            {
                alert('Submitted Sucessfully')
            }
            else {
                alert('Error in code')
            }
        }) 
    }

    const [reg, setReg] = useState([])
    useEffect(() => {
        loadplan();
    })
    const loadplan = async () => {
        const result =await axios.get("http://localhost/matrimony/view_plan.php");
        setReg(result.data.record);
    }
    
    const deletereg=(id)=>{
        
        axios.delete("http://localhost/matrimony/delete_plan.php",{data:{id:id}})
        .then((res)=>{
         if(res.data==1)
         {
           alert("Deleted successfully")
           loadplan();
         }
         else{
           alert("Sorry ! try again")
         }
       })
   }

    return<>
    <title>Matrimonial</title>
    <Adminheader/>
			

			
		<div class="has-navbar-mobile">

        <main>

		
        <section class="vh-xxl-100">
				<div class="container px-0 px-sm-4">
					<div class="row justify-content-center align-items-center m-auto">
						<div class="col-12">
                        
							<div class="bg-mode shadow rounded-3 overflow-hidden">
								<div class="row g-0">									
									<div class="col-lg-7 mx-auto">
										<div class="p-3 p-sm-4">
											<Link to="/">
												<img class="h-50px mb-4" src="assets/images/logo.png" alt="logo"/>
											</Link>
											<h1 class="mb-2 h3">Create User Plan</h1>
					
											<form class="mt-4 text-start" onSubmit={e => submitform(e)} >
												<div class="row">
													
													<div class="col-md-6 col-sm-12 mb-3">
														<select class="form-select js-choice" name="planname" onChange={e => setPtype(e.target.value)} >
															<option value="" disabled selected>Select Plan</option>
															<option>Gold</option>
															<option>Platinum</option>
															<option>Daimond</option>
														</select>
													</div>
													<div class="col-md-6 col-sm-12 mb-3">
														<input type="text" class="form-control" name="price" placeholder="Enter Price" onChange={e => setPprice(e.target.value)}/>
													</div>
													<div class="col-md-12 col-sm-12 mb-3">
														<textarea name="description" class="form-control" placeholder="Enter Plan Description" onChange={e => setPdesc(e.target.value)}></textarea>
													</div>
													
													<div><button type="submit" class="btn btn-primary w-100 mb-0">Submit</button></div>
													
						
												</div>	
											</form>
										</div>		
									</div>
								</div>
							</div>
						</div>
                        <div class="col-12">
                        
							<div class="bg-mode shadow rounded-3 overflow-hidden">
								<div class="row g-0">									
									<div class="col-lg-7 mx-auto">
										<div class="p-3 p-sm-4">
											
											<h1 class="mb-2 h3">Created User Plan</h1>
					
							<table class='table table-bordered'>
                            <tr>
                                    <th>id</th>
                                    <th>Plan type</th>
                                    <th>Price</th>
                                    <th>Description</th>
                                    <th>Action</th>

                                </tr>
                                {reg.map((reg, index) => (
                                    <tr>
                                        <td>{reg.id}</td>
                                        <td>{reg.ptype}</td>
                                        <td>{reg.pprice}</td>
                                        <td>{reg.pdesc}</td>
                                        <td><Link to="" onClick={()=>deletereg(reg.id)}><i class="bi bi-trash"></i></Link>
                                        <Link to={'/edit_plan/'+reg.id} ><i class="bi bi-pen"></i></Link></td>


                                    </tr>
                                    ))}
                                
                            </table>
										</div>		
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</section>
		

    </main>
    </div>
    </>
}
export default Createplan;