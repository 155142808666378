import {Link} from 'react-router-dom';
import Header from './header';
import axios from 'axios';
import { useEffect,useState } from 'react';

function Plan() {
	let uid=sessionStorage.getItem('uid')
	let upass=sessionStorage.getItem('upass')

	const [reg, setReg] = useState([])
    useEffect(() => {
        loadplan();
    },[])
    const loadplan = async () => {
        const result =await axios.get("http://localhost/matrimony/view_plan.php");
        setReg(result.data.record);
    }
return<>

    <title>Matrimonial</title>
	<Header/>

   
	<div class="has-navbar-mobile">

    <main>
    
            <section class="price-wrap pt-4 pt-sm-0">
				<div class="container">
					<div class="row">

						<div class="col-xl-10 mx-auto text-center">
							<h6 class="text-primary">Pricing</h6>
							<h1 class="mb-2">Plan That Fit Your
								<span class="position-relative z-index-9">Scale

								</span>
							</h1>
							<p class="lead mb-0">Simple, transparent pricing that grows with you. Try any plan free for 30 days</p>
						</div>
					</div>
					<div class="row g-4 justify-content-center mt-5"> 
						{reg.map((reg, index) => (

						
						<div class="col-md-6 col-lg-4">
							<div class="card shadow">

								<div class="card-header d-flex justify-content-between align-items-center border-bottom p-4">
									<div>
										<h6 class="mb-0">{reg.ptype} </h6>
										<div class="hstack gap-2">
											<span class="h3 plan-price mb-0">{reg.pprice}</span>
											<span class="h6 fw-light mb-0">/ per user</span>
										</div>
									</div>
									<span class="icon-lg bg-light rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Most Popular">
										<i class="bi bi-lightning-charge-fill h5 lh-1"></i>
									</span>
								</div>

								<div class="card-body p-4">

									<ul class="list-unstyled mb-0">
										<li class="mb-2"><i class="bi bi-check-circle text-success me-2"></i>{reg.pdesc} </li>
									</ul>
								</div>

								<div class="card-footer p-4 pt-0">
									{(()=>{
										if(uid=='' && upass=='')
										{
										return<>
										<Link to={'/ulogin/'} class="btn btn-dark w-100 mb-0">Get Started</Link>
										</>	
										}
										else{
											return<>
											<Link to={'/transaction/'+reg.id} class="btn btn-dark w-100 mb-0">Get Started</Link>
											</>
										}
									})()}
									
								</div>
							</div>
						</div>

						))}
						
					</div>
				</div>
			</section>
				
    
    </main>
    </div>

</>
}
export default Plan;