import { useEffect, useState } from "react"
import axios from 'axios'
import Adminheader from "./admin_header"
import Adminfooter from "./admin_footer"
import { Link } from "react-router-dom"

function Userdetail() {
    const [reg, setReg] = useState([])
    useEffect(() => {
        loadreg();
    })
    const loadreg = async () => {
        const result =await axios.get("http://localhost/matrimony/view_user.php");
        setReg(result.data.record);
    }
    
    const deletereg=(id)=>{
        
        axios.delete("http://localhost/matrimony/delete_user.php",{data:{id:id}})
        .then((res)=>{
         if(res.data==1)
         {
           alert("Deleted successfully")
           loadreg();
         }
         else{
           alert("Sorry ! try again")
         }
       })
   }

    return <>
        <Adminheader />
        <center>
            <form>
                <div class='container mt-5'>
                    <div class='row'>
                        <div class='col-3'></div>
                        <div class='col-12'>
                            <h3>User Details</h3>
                            <table class='table table-bordered'>
                            <tr>
                                    <th>Name</th>
                                    <th>Gender</th>
                                    <th>Mo. Number</th>
                                    <th>Address</th>
                                    <th>Action</th>
                                    <th>Action</th>
                                    <th>Action</th>
                                </tr>
                                {reg.map((reg, index) => (
                                    <tr>
                                        <td>{reg.na}</td>
                                        <td>{reg.gen}</td>
                                        <td>{reg.cno}</td>
                                        <td>{reg.addr}</td>
                                        <td><Link to={'/view_user_plan/'+reg.em}>View User Plan</Link></td>

                                        <td><Link to={'/view_user/'+reg.em}>View User</Link></td>
                                        <td><Link to="" onClick={()=>deletereg(reg.id)}>Delete</Link></td>

                                    </tr>
                                    ))}
                                
                            </table>

                        </div>
                    </div>
                </div>
            </form>
        </center>

        <Adminfooter />
    </>
}
export default Userdetail;